import {MenuItem, TextField} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";


export const LanguageDropdown: React.FC = () => {
    const {i18n} = useTranslation();


    const onChangeLang = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
        console.log(lang_code)
    };


    return (
        <>
            <TextField
                value={i18n.language}
                onChange={onChangeLang}
                select
                variant="standard"
                sx={{width: 150, mr: 2, color: "black"}}
                inputProps={{style: {color: "black"}}}
            >
                <MenuItem key="de" value="de">Deutsch</MenuItem>
                <MenuItem key="en" value="en">English</MenuItem>
                <MenuItem key="fr" value="fr">Français</MenuItem>
            </TextField>

        </>
    )
}