import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { uploadFetch, useFetch } from "../../../hooks/useFetch";
import { Alert, AppBar, Box, Button, Drawer, Fade, Step, StepLabel, Stepper, Toolbar, Typography } from "@mui/material";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { CustomeSlide } from "../../core/CustomeSlide";
import Send from "../../generic/Send";
import { ISystem } from "../../../Interfaces/ISystem";
import { IConfig } from "../../../Interfaces/IConfig";
import { DialogFrame } from "../../core/DialogFrame";





interface IProps {
    configObject: IConfig;
}



export const InviteDialogAddLegalTransaction: React.FC<IProps> = ({configObject}) => {
    const params = useParams();
    const [tokenValide, setTokenValide, wasSuccessfully] = useFetch<{Text: string|null}>("/contactinvite/legaltransaction/validate/",params.token);
    //
    const [currentPage,setCurrentPage] = useState(0);
    //
    const [isConfirmed,setIsConfirmed] = useState<null|boolean>(null);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSuccessfullyUploaded,setWasSuccessfullyUploaded] = useState(true);

    const handleSend = () => {
        let uploadObject = {
            TempToken: params.token,
            isAssumed: isConfirmed
        }
        uploadFetch(`/contactinvite/answer/${params.token}`,true,uploadObject,() => setCurrentPage(1),setWasSuccessfullyUploaded,setWasSaved,setIsLoading)

    }


    const contextTSx = () => {
        if (!wasSuccessfully) {
            return(
                <>
                    <Alert severity="warning">
                        Die Einladung konnte nicht gefunden werden oder ist bereits abgelaufen.
                        <br/>In dringenden Fällen kontaktieren Sie uns bitte.
                    </Alert>
                </>
            )
        }
        else if (!wasSuccessfullyUploaded) {
            return(
                <>
                    <Alert severity="error">
                        Ihre Antwort konnte nicht hochgeladen werden.
                        <br/>In dringenden Fällen kontaktieren Sie uns bitte.
                    </Alert>
                </>
            )
        }
        else if (isLoading || tokenValide === undefined) {
            return(
                <>
                    <CustomCircularProgress/>
                </>
            )
        }
        else {
            return(
                <>
                    <CustomeSlide currentPage={currentPage} pageNumber={0} lastCurrentPage={-1} appear={false}>
                        <>
                            <Alert severity="info">
                                <Typography variant="body1">
                                    Sie wurden zu einem Vorgang eingeladen.
                                    <br/>Es wurde folgende Nachricht für Sie hinterlegt:
                                    
                                    <Box sx={{mt:2, mb: 2}}>
                                        <i>{(tokenValide.Text === null || tokenValide.Text === "") ? <>k.A.</> : tokenValide.Text}</i>
                                    </Box>
                                    Bei einer Annahme wird der Vorgang Ihrem DiNo-Konto zugeordnet.
                                    <p/>
                                    Wollen Sie die Einladung zum Vorgang annehmen? 
                                    
                                </Typography>
                            </Alert>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="error"
                                    variant={(isConfirmed === null || isConfirmed) ? "outlined" : "contained"}
                                    onClick={() => setIsConfirmed(false)}
                                    sx={{ mr: 1 }}
                                >
                                    Ablehnen
                                </Button>

                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button 
                                    color="success"
                                    variant={(isConfirmed === null || !isConfirmed) ? "outlined" : "contained"}
                                    onClick={() => setIsConfirmed(true)}
                                >
                                    Annehmen
                                </Button>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Fade in={isConfirmed !== null}>
                                    <Button 
                                        variant="contained"
                                        onClick={handleSend}
                                    >
                                        Absenden
                                    </Button>
                                </Fade>
                            </Box>


                        </>
                    </CustomeSlide>

                    <CustomeSlide currentPage={currentPage} pageNumber={1} lastCurrentPage={0}>
                        <Send/>
                    </CustomeSlide>
                </>
            )
        }
    }


    return(
        <>
            <DialogFrame
                configObject={configObject}
                title="Einladung zum Vorgang"
                currentPage={currentPage}
                disableHorizontalWarning
                stepperArray={[
                    <Step key="invite0" completed={currentPage > 0}><StepLabel>Vorgang bestätigen</StepLabel></Step>
                ]}
            >
                <>
                {contextTSx()}
                </>
            </DialogFrame>

        </>
    )
}