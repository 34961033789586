import React, { useState } from "react";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { ChildrenRow } from "./ChildrenRow";
import { IInheritHasPerson } from "../../../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { IChildren } from "../../../../../../../Interfaces/IChildren";
import { ChildrenAddDialog } from "./ChildrenAddDialog";
import { IInherit } from "../../../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../../../Interfaces/IMarriage";

export interface IChildrenTableColDef {
    titleFirstParent: string;
    isSecondParentEnabled: boolean;
}

interface IProps {
    inheritObject: IInherit;
    firstParentArray: IInheritHasPerson[];
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    childrenArray: IChildren[];
    setChildrenArray: Function;
    marraigeArray: IMarriage[];
}



export const ChildrenTable:React.FC<IProps> = (props) => {
    const [isNewOpen, setIsNewOpen] = useState(false);

    const getLocalChildrenArray = () => {
        return props.childrenArray
            .filter(x => props.firstParentArray.map(y => y.idPerson).indexOf(x.Parent1_idPerson) > -1)
    }


    return(
        <>
            <Typography variant="h5">
                Nachkommen
            </Typography>

            <Typography variant="body1" sx={{mt: 2, mb: 3}}>
                Bitte erfassen Sie alle Nachkommen von {props.firstParentArray.map(x => `${x.FirstName} ${x.LastName}`).join(" und ")}
            </Typography>

            {(isNewOpen) &&
                <ChildrenAddDialog
                    inheritObject={props.inheritObject}
                    parentPersonArray={props.firstParentArray}
                    setIsOpenNew={setIsNewOpen}
                    personArray={props.personArray}
                    setPersonArray={props.setPersonArray}
                    personHasDocument={props.personHasDocument}
                    setPersonHasDocument={props.setPersonHasDocument}
                    childrenArray={props.childrenArray}
                    setChildrenArray={props.setChildrenArray}
                    marriageArray={props.marraigeArray}
                />

            }

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        <TableCell>Elternteil</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getLocalChildrenArray().map((x,idx) =>
                        <ChildrenRow
                            key={`idChildren-${x.idChildren}`}
                            index={idx}
                            currentChildren={x}
                            inheritObject={props.inheritObject}
                            setIsOpenNew={setIsNewOpen}
                            personArray={props.personArray}
                            setPersonArray={props.setPersonArray}
                            personHasDocument={props.personHasDocument}
                            setPersonHasDocument={props.setPersonHasDocument}
                            childrenArray={props.childrenArray}
                            setChildrenArray={props.setChildrenArray}
                            marriageArray={props.marraigeArray}
                            parentPersonArray={props.firstParentArray}
                        />
                    )}
                </TableBody>
                <br></br>
                <Button variant="contained" onClick={() => setIsNewOpen(true)} sx={{float: "left"}}>Kind Hinzufügen</Button>
            </Table>
        </>
    )
}
//<IconButton onClick={() => setIsNewOpen(true)} sx={{float: "right"}}><Add/></IconButton>