import { Alert, AppBar, Box, Button, Collapse, Dialog, DialogContent, Drawer, Fade, Grow, Paper, Slide, Step, StepLabel, Stepper, Toolbar, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { IInheritHasPerson, IPerson } from "../../../../Interfaces/IPerson";
import { Star } from "@mui/icons-material";
import { IHelpSection } from "../../../../Interfaces/IHelpSection";
import Send from "../../../generic/Send";
import { SaveProgress } from "../SaveProgress";
import { RestoreProgress } from "../RestoreProgress";
import { IPersonHasDocument } from "../../../../Interfaces/IPersonHasDocument";
import { IMarriage } from "../../../../Interfaces/IMarriage";
import { IChildren } from "../../../../Interfaces/IChildren";
import { CustomeSlide } from "../../../core/CustomeSlide";
import { JSONTree } from "react-json-tree";
import { Start } from "./Childs/Dialog/Start";
import { Inherit } from "./Childs/Dialog/Inherit";
import { Marriage } from "./Childs/Dialog/Marriage";
import { ChildrenDriver } from "./Childs/Components/Children/ChildrenDriver";
import { getInheritPerson } from "./Childs/Components/InheritPerson";
import { Parents } from "./Childs/Dialog/Parents";
import { FamilyTree } from "./Childs/Components/FamilyTree";
import { Summary } from "./Childs/Dialog/Summary";
import { IInherit } from "../../../../Interfaces/IInherit";
import { Strangers } from "./Childs/Dialog/Strangers";
import { useHotkeys } from "react-hotkeys-hook";
import { IConfig } from "../../../../Interfaces/IConfig";
import { DialogFrame } from "../../../core/DialogFrame";
import { DialogResponsive } from "../../../core/DialogResponsive";

interface IProps {
    configObject: IConfig;
}

export const InheritDialogNew: React.FC<IProps> = ({configObject}) => {
    const [isEndOfInheritanceChain, setIsEndOfInheritanceChain] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [lastCurrentStep, setLastCurrentStep] = useState(0);
    const [activeStepCounter, setActiveStepCounter] = useState(0);
    //
    const isDesktop = useMediaQuery('(min-width:900px)'); 

    const [txt, setTxt] = useState<React.ReactNode>(<></>);

    const [inheritObject,setInheritObject] = useState({
        hasTestament: false,
        idLegalTransaction: -1,
        idInherit: -1,
        idPerson: -1
    } as IInherit);

    const [personArray, setPersonArray] = useState<IInheritHasPerson[]>([
        {
            idPerson: -1,
            Birthday: "",
            Birthplace: "",
            DateOfDeath: null,
            FirstName: "",
            idAcademicTitle: 1,
            idCountry: 1,
            idGender: -1,
            idInherit: -1,
            idPostcode: null,
            LastName: "",
            PlaceOfDeath: null,
            Street: "",
            Relation_idPerson: null,
            StreetAditional: "",
            StreetNr: "",
            Surname: null,
            idBusinessForm: 1,
            isStranger: false,

            Email: null,
            PhoneNumber: null,

            TaxNumber: null,
            IBAN: null,
            BIC: null,
            Bank: null,
        } as IInheritHasPerson
    ]);
    const [personHasDocument, setPersonHasDocument] = useState<IPersonHasDocument[]>([]);
    const [marriageArray, setMarriageArray] = useState<IMarriage[]>([]);
    const [childrenArray, setChildrenArray] = useState<IChildren[]>([]);

    const [isDebugEnabled, setIsDebugEnabled] = useState(false);
    const [isOpenJsonDebug, setIsOpenJsonDebug] = useState(false);


    useHotkeys('shift+d', () => setIsDebugEnabled(!isDebugEnabled));

    useEffect(() => {
        console.log(personArray)
        console.log(marriageArray)
        console.log(childrenArray)

    });

    /* ###############################  BEGIN SAVE AND RESTORE ############################### */
    const getDataToSave = () => {
        let tempDataToSave = {
            CurrentStep: currentStep,
            LastCurrentStep: lastCurrentStep,
            ActiveStepCounter: activeStepCounter,
            //Txt: txt,
            IsEndOfInheritanceChain: isEndOfInheritanceChain,

            InheritObject: inheritObject,
            PersonArray: personArray,
            ChildrenArray: childrenArray,
            MarriageArray: marriageArray,
            // Children and Decendants
            PersonHasDocument: personHasDocument,
        }
        return tempDataToSave
    }

    const restoreSetterFunction  = (uplodatedFile: any) => {
        if ("CurrentStep" in uplodatedFile) {
            setCurrentStep(uplodatedFile["CurrentStep"])
        }
        if ("LastCurrentStep" in uplodatedFile) {
            setLastCurrentStep(uplodatedFile["LastCurrentStep"])
        }
        if ("ActiveStepCounter" in uplodatedFile) {
            setActiveStepCounter(uplodatedFile["ActiveStepCounter"])
        }
        if ("Txt" in uplodatedFile) {
            setTxt(uplodatedFile["Txt"])
        }
        if ("IsEndOfInheritanceChain" in uplodatedFile) {
            setIsEndOfInheritanceChain(uplodatedFile["IsEndOfInheritanceChain"])
        }
        if ("InheritObject" in uplodatedFile && uplodatedFile["InheritObject"] !== inheritObject) {
            setInheritObject(uplodatedFile["InheritObject"])
        }
        if ("PersonArray" in uplodatedFile && uplodatedFile["PersonArray"] !== personArray) {
            setPersonArray(uplodatedFile["PersonArray"])
        }
        if ("PersonHasDocument" in uplodatedFile && uplodatedFile["PersonHasDocument"] !== personHasDocument) {
            setPersonHasDocument(uplodatedFile["PersonHasDocument"])
        }
        if ("MarriageArray" in uplodatedFile && uplodatedFile["MarriageArray"] !== marriageArray) {
            setMarriageArray(uplodatedFile["MarriageArray"])
        }
        if ("ChildrenArray" in uplodatedFile && uplodatedFile["ChildrenArray"] !== childrenArray) {
            setChildrenArray(uplodatedFile["ChildrenArray"])
        }
    }

    /* ###############################  END SAVE AND RESTORE ############################### */


    useEffect(() => {
     console.log(personHasDocument)
    });
    const getDeathParentArray = () => {
        let foundChildren = childrenArray.find(x => x.Children_idPerson === -1);

        if (foundChildren === undefined) {
            return [] as IInheritHasPerson[]
        } 
        else {
            let returnArray:IInheritHasPerson[] = [];
            let foundFirstParent = personArray.find(x => x.idPerson === foundChildren?.Parent1_idPerson);
            let foundSecondParent = personArray.find(x => x.idPerson === foundChildren?.Parent2_idPerson);

            if (foundFirstParent !== undefined && foundFirstParent.DateOfDeath !== null && foundSecondParent !== undefined && foundSecondParent.DateOfDeath !== null) {
                //foundFirstParent.LastName = `und ${foundSecondParent.FirstName} ${foundSecondParent.LastName}`;
                returnArray.push(foundFirstParent)
            }
            else {
                if (foundFirstParent !== undefined && foundFirstParent.DateOfDeath !== null) {
                    returnArray.push(foundFirstParent)
                }
    
                if (foundSecondParent !== undefined && foundSecondParent.DateOfDeath !== null) {
                    returnArray.push(foundSecondParent)
                }
            }

            return returnArray;
        }
    }


    const handleNext = (nextPage?:number) => {
        setLastCurrentStep(currentStep);

        if (nextPage === undefined) {
            setCurrentStep(currentStep + 1);
        } else {
            setCurrentStep(nextPage);
        }
        
    }

    const handleBack = () => {
        let tmpStep = currentStep;

        // Wenn abstand mehr als einer ist, dann springe auf lastCurrentStep
        if ((tmpStep - lastCurrentStep) > 1 ) {
            setCurrentStep(lastCurrentStep)
        } else {
            setCurrentStep(currentStep - 1);
        }
        setLastCurrentStep(currentStep);
        //setCurrentStep(currentStep - 1);
        //let tmpStep = currentStep;
        //setCurrentStep(lastCurrentStep);
        //setLastCurrentStep(tmpStep);
    }

    const enableParents = () => {

    }


    return (
        <>
            <DialogFrame
                configObject={configObject}
                title="Formular zum Erbscheinsantrag"
                currentPage={currentStep}
                stepperArray={[
                    <Step key={`inherit-0`} completed={currentStep > 0}><StepLabel>Erbscheinsantrag</StepLabel></Step>,
                    <Step key={`inherit-1`} completed={currentStep > 1}><StepLabel>Erblasser/in</StepLabel></Step>,
                    <Step key={`inherit-2`} completed={currentStep > 2}><StepLabel>Ehen</StepLabel></Step>,
                    <Step key={`inherit-3`} completed={currentStep > 3}><StepLabel>Nachkommen</StepLabel></Step>,
                    <Step key={`inherit-4`} completed={currentStep > 4}><StepLabel>Eltern</StepLabel></Step>,
                    <Step key={`inherit-5`} completed={currentStep > 5}><StepLabel>Verwandschaft</StepLabel></Step>,
                    <Step key={`inherit-7`} completed={currentStep > 6}><StepLabel>Sonstige Personen</StepLabel></Step>,
                    <Step key={`inherit-8`} completed={currentStep > 7}><StepLabel>Zusammenfassung</StepLabel></Step>
                ]}
                headerButtonArray={[
                    <RestoreProgress key="inherit-import" setterFunction={restoreSetterFunction} ></RestoreProgress>,
                    <SaveProgress key="inherit-export" idLegaltransaction={100} dataToSave={getDataToSave()} filename={"Erbscheinsantrag"}></SaveProgress>
                ]}
                txt={""}
            >

                        <Collapse in={isEndOfInheritanceChain}>
                            <Alert severity="warning">Bei Anträgen mit mehreren Generationen als Ururenekel/in oder Ururgroß- neffen / nichten bitte melden Sie sich persönlich bei uns</Alert>
                            <br></br>
                        </Collapse>

                        
                        <CustomeSlide currentPage={currentStep} pageNumber={0} lastCurrentPage={lastCurrentStep} appear={false}>
                            <Start currentStep={currentStep} setcurrentStep={setCurrentStep} handleNext={handleNext} />
                        </CustomeSlide>

                        <CustomeSlide currentPage={currentStep} pageNumber={1} lastCurrentPage={lastCurrentStep}>
                            <Inherit
                                personArray={personArray}
                                setPersonArray={setPersonArray}
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonHasDocument}
                                inheritObject={inheritObject}
                                setInheritObject={setInheritObject}
                                handleBack={handleBack}
                                handleNext={handleNext}
                            />
                        </CustomeSlide>

                        <Collapse 
                            appear={true} 
                            in={currentStep > 1 && currentStep < 8} 
                            mountOnEnter unmountOnExit
                        >
                            <Box sx={{mb: 5}}>
                                { (isDesktop) ?
                                    <FamilyTree
                                        personArray={personArray}
                                        childrenArray={childrenArray}
                                        marriageArray={marriageArray}
                                        setPersonArray={setPersonArray}
                                        setChildrenArray={setChildrenArray}
                                        setMarriageArray={setMarriageArray}
                                        personHasDocument={personHasDocument}
                                        setPersonHasDocument={setPersonHasDocument} 
                                        setFamilyTreeData={() => {}}                                
                                    />
                                    : <Alert severity="info">
                                        Der interaktiver Stammbaum ist nur unter größeren Endgeräten verfügbar (mindestens 900px).
                                    </Alert>


                                }

                            </Box>
                        </Collapse>


                        <CustomeSlide currentPage={currentStep} pageNumber={2} lastCurrentPage={lastCurrentStep}>
                            <Marriage
                                inheritObject={inheritObject}
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonHasDocument}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                marriageArray={marriageArray}
                                setMarriageArray={setMarriageArray}
                                personArray={personArray}
                                setPersonArray={setPersonArray}
                            />
                        </CustomeSlide>

                        <CustomeSlide currentPage={currentStep} pageNumber={3} lastCurrentPage={lastCurrentStep}>
                            <ChildrenDriver
                                inheritObject={inheritObject}
                                parrentPersonArray={[getInheritPerson(-1,personArray)]}
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonHasDocument}
                                personArray={personArray}
                                setPersonArray={setPersonArray}
                                childrenArray={childrenArray}
                                setChildrenArray={setChildrenArray}
                                marriageArray={marriageArray}
                                handleBack={handleBack}
                                handleNext={handleNext}
                            />
                        </CustomeSlide>

                        <CustomeSlide currentPage={currentStep} pageNumber={4} lastCurrentPage={lastCurrentStep}>
                            <Parents
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonHasDocument}
                                personArray={personArray}
                                setPersonArray={setPersonArray}
                                childrenArray={childrenArray}
                                setChildrenArray={setChildrenArray}
                                inheritObject={inheritObject}
                                handleBack={handleBack}
                                handleNext={handleNext}
                            />
                        </CustomeSlide>

                        <CustomeSlide currentPage={currentStep} pageNumber={5} lastCurrentPage={lastCurrentStep}>
                            <ChildrenDriver
                                inheritObject={inheritObject}
                                parrentPersonArray={getDeathParentArray()}
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonHasDocument}
                                personArray={personArray}
                                setPersonArray={setPersonArray}
                                childrenArray={childrenArray}
                                setChildrenArray={setChildrenArray}
                                marriageArray={marriageArray}
                                handleBack={handleBack}
                                handleNext={handleNext}
                            />
                        </CustomeSlide>

                    

                        <CustomeSlide currentPage={currentStep} pageNumber={6} lastCurrentPage={lastCurrentStep}>
                        {(inheritObject.hasTestament) ?
                            <Strangers
                                inheritObject={inheritObject}
                                personArray={personArray}
                                setPersoArray={setPersonArray}
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonArray}
                                handleBack={handleBack}
                                handleNext={handleNext}
                            />
                            :
                            <Box sx={{mt: 10}}>
                                <Alert severity="warning">
                                    Es konnte keine Erben bestimmt werden.
                                    <br/>Bitte erfassen Sie weiter Verwandschaft im Textfeld <i>Anmerkungen</i>
                                </Alert>
                                <Box sx={{ mt: 5 }} />
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleBack()}
                                        sx={{ mr: 1 }}
                                    >
                                        Zurück
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button type="submit" variant="contained" onClick={() => handleNext()}>
                                        Weiter
                                    </Button>
                                </Box>
                            </Box>
                        }
                        </CustomeSlide>


                    
                        <CustomeSlide currentPage={currentStep} pageNumber={7} lastCurrentPage={lastCurrentStep}>
                            <Summary
                                configObject={configObject}
                                inheritObject={inheritObject}
                                childrenArray={childrenArray}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                marriageArray={marriageArray}
                                personArray={personArray}
                                personHasDocument={personHasDocument}
                            />
                        </CustomeSlide>

                        <CustomeSlide currentPage={currentStep} pageNumber={8} lastCurrentPage={lastCurrentStep}>
                            <Send />
                        </CustomeSlide>




                        <Dialog
                            open={isOpenJsonDebug}
                            onClose={() => setIsOpenJsonDebug(false)}
                            maxWidth="lg" fullWidth
                        >
                            <DialogContent>
                                <JSONTree data={{
                                    personArray: personArray,
                                    personHasDocument: personHasDocument,
                                    marriageArray: marriageArray,
                                    childrenArray: childrenArray,
                                }} />
                            </DialogContent>
                        </Dialog>

                              
                        {
                            (isDebugEnabled) &&
                            <>
                                <b>DEBUG</b>
                                <br />{`currentPage: ${currentStep}`}
                                <br /><Button onClick={() => setCurrentStep(1)}>Zurück zum Erblasse</Button>
                                <br /><Button onClick={() => setIsOpenJsonDebug(true)}>Json Tree</Button>
                            </>
                        }
                        
            </DialogFrame>
        </>
    )
}
