import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    handleBack: Function;
    handleNext: Function;
}


export const PurchaseContractStart:React.FC<IProps> = (props) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Kaufvertrag</Typography>

            <Typography>
            Willkommen auf unserer Online-Plattform für die schnelle Erstellung von maßgeschneiderten Kaufverträgen! Geben Sie einfach Ihre Kontaktdaten, finanziellen Informationen und speziellen Anforderungen ein. Unser benutzerfreundlicher Prozess führt Sie durch die Schritte, um Ihren Vertrag zu generieren. Im Anschluss haben Sie die Möglichkeit, Ihren Kaufvertrag direkt auf unsere digitale Notarplattform hochzuladen. Vereinfachen Sie Ihren Immobilienkauf – effizient und sicher! Bei Fragen steht Ihnen unser Support-Team zur Verfügung.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}