import React, {useEffect, useState} from "react";
import {ICompanyFormation} from "../../../../Interfaces/ICompanyFormation";
import {IShareHolder, IShareHolderFullObject} from "../../../../Interfaces/IShareHolder";
import {ICompanyFormationShare} from "../../../../Interfaces/ICompanyFormationShare";
import {ICompanyFormationHasShareHolder} from "../../../../Interfaces/ICompanyFormationHasShareHolder";
import {Alert, AppBar, Box, Button, Drawer, Step, StepLabel, Stepper, TextField, Toolbar, Typography} from "@mui/material";
import {RestoreProgress} from "../RestoreProgress";
import {SaveProgress} from "../SaveProgress";
import {CustomeSlide} from "../../../core/CustomeSlide";
import Send from "../../../generic/Send";
import {ICompanyFormationAnswer} from "../../../../Interfaces/ICompanyFormationAnswer";
import {useFetch} from "../../../../hooks/useFetch";
import {ICompanyFormationQuestion} from "../../../../Interfaces/ICompanyFormationQuestion";
import {ICompanyFormationOption} from "../../../../Interfaces/ICompanyFormationOption";
import {CustomCircularProgress} from "../../../generic/CustomCircularProgress";
import { RABaseInformation } from "./Childs/RABaseInformation";
import { RABoardMemebers } from "./Childs/RABoardMemebers";
import { RARepresentative } from "./Childs/RARepresentative";
import { IDocument } from "../../../../Interfaces/IDocument";
import { RASummary } from "./Childs/RASummary";
import { RAConstitution } from "./Childs/RAConstitution";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../../../core/LanguageDropdown";
import { IConfig } from "../../../../Interfaces/IConfig";
import { DialogFrame } from "../../../core/DialogFrame";




interface IProps {
    configObject: IConfig;
}



export const FoundRegisteredAssociationDialog: React.FC<IProps> = ({configObject}) => {
    const { t, i18n } = useTranslation();
    const [languageIsLoading,setLanguageIsLoading] = useState(true);
    const [errorLanguage,setErrorLanguage] = useState(false);

    const [lanuageSection,setLanuageSection,wasSuccessfullyLanguage] = useFetch<any>("/languagesection/2");

    const [companyFormationOptionArray, setCompanyFormationOptionArray, wasSuccessfullCompanyFormationOptionArray] = useFetch<ICompanyFormationOption[]>("/companyformationoption")
    const [companyFormationQuestionArray, setCompanyFormationQuestionArray, wasSuccessfullCompanyFormationQuestionArray] = useFetch<ICompanyFormationQuestion[]>("/companyformationquestion")

    const [companyFormationObject, setCompanyFormationObject] = useState<ICompanyFormation>({
        idCompanyFormation: -1,
        idBusinessForm: 12,
        idLegalTransaction: -1,
        idPostcode: -1,
        CompanyName: "",
        DateOfFormation: new Date(),
        PurposeOfTheCompany: "",
        ShareCapital: 0,
        Street: "",
        StreetNr: "",
        AmountShares: 0,
        ValuePerShare: 0,
        useSampleProtocol: false,
        Remarks: "",
        AddressStreet: null,
        AddressStreetNr: null,
        Postcode_AddressidPostcode: null
    })
    const [shareHolderArray, setShareHolderArray] = useState<IShareHolderFullObject[]>([]);
    const [companyFormationShareArray, setCompanyFormationShareArray] = useState<ICompanyFormationShare[]>([]);
    const [shareHolderRelation, setShareHolderRelation] = useState<ICompanyFormationHasShareHolder[]>([]);
    const [companyFormationAnswerArray, setCompanyFormationAnswerArray] = useState<ICompanyFormationAnswer[]>([]);
    const [documentArray,setDocumentArray] = useState<IDocument[]>([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [lastCurrentPage, setLastCurrentPage] = useState(-1);
    const [txt, setTxt] = useState("");

    const [openImportFromHandelsregister, setOpenImportFromHandelsregister] = useState(false);

    const [stepFounding0, setStepFounding0] = useState("Vereinsgründung");
    const [stepFounding1, setStepFounding1] = useState("Grundinformationen");
    const [stepFounding2, setStepFounding2] = useState("Vorstand");
    const [stepFounding3, setStepFounding3] = useState("Vertretung");
    const [stepFounding4, setStepFounding4] = useState("Abschluss");

    const [isImportedFromHandelsregister, setIsImportedFromHandelsregister] = useState(false)


    const getDataToSave = () => {
        let tempDataToSave = {
            //CompanyFormationOptionArray: companyFormationOptionArray,
            //CompanyFormationQuestionArray: companyFormationQuestionArray,
            CurrentPage: currentPage,
            lastCurrentPage: lastCurrentPage,
            //txt: txt,
            CompanyFormationObject: companyFormationObject,
            ShareHolderArray: shareHolderArray,
            ShareHolderRelation: shareHolderRelation,
            DocumentArray: documentArray,
            CompanyFormationShareArray: companyFormationShareArray, 
            CompanyFormationAnswerArray : companyFormationAnswerArray
        }
        return tempDataToSave
    }

    const restoreSetterFunction  = (uplodatedFile: any) => {
        console.log("restore")
        /*if("CompanyFormationOptionArray" in uplodatedFile && uplodatedFile["CompanyFormationOptionArray"] !== companyFormationOptionArray)
        {
            setCompanyFormationOptionArray(uplodatedFile["CompanyFormationOptionArray"])
        }
        if("CompanyFormationQuestionArray" in uplodatedFile && uplodatedFile["CompanyFormationQuestionArray"] !== companyFormationQuestionArray)
        {
            setCompanyFormationQuestionArray(uplodatedFile["CompanyFormationQuestionArray"])
        }
        */
        if ("CurrentPage" in uplodatedFile && uplodatedFile["CurrentPage"] !== currentPage) {
            setCurrentPage(uplodatedFile["CurrentPage"])
        }
        if ("lastCurrentPage" in uplodatedFile) {
            setLastCurrentPage(uplodatedFile["lastCurrentPage"])
        }
        if ("CompanyFormationObject" in uplodatedFile && uplodatedFile["CompanyFormationObject"] !== companyFormationObject) {
            setCompanyFormationObject(uplodatedFile["CompanyFormationObject"])
        }
        if ("ShareHolderArray" in uplodatedFile && uplodatedFile["ShareHolderArray"] !== shareHolderArray) {
            setShareHolderArray(uplodatedFile["ShareHolderArray"])
        }
        if ("ShareHolderRelation" in uplodatedFile && uplodatedFile["ShareHolderRelation"] !== shareHolderRelation) {
            setShareHolderRelation(uplodatedFile["ShareHolderRelation"])
        }
        if ("DocumentArray" in uplodatedFile && uplodatedFile["DocumentArray"] !== documentArray) {
            setDocumentArray(uplodatedFile["DocumentArray"])
        }
        if ("CompanyFormationShareArray" in uplodatedFile && uplodatedFile["CompanyFormationShareArray"] !== companyFormationShareArray) {
            setCompanyFormationShareArray(uplodatedFile["CompanyFormationShareArray"])
        }
        if ("CompanyFormationAnswerArray" in uplodatedFile && uplodatedFile["CompanyFormationAnswerArray"] !== companyFormationAnswerArray) {
            setCompanyFormationAnswerArray(uplodatedFile["CompanyFormationAnswerArray"])
        }
    }

    {/*
        const restoreSetterFunction = (uplodatedFile: any) => {
        if("CompanyFormationOptionArray" in uplodatedFile)
        {
            setCompanyFormationOptionArray(uplodatedFile["CompanyFormationOptionArray"])
        }
        if("CompanyFormationQuestionArray" in uplodatedFile)
        {
            setCompanyFormationQuestionArray(uplodatedFile["CompanyFormationQuestionArray"])
        }
        if ("CurrentPage" in uplodatedFile) {
            setCurrentPage(uplodatedFile["CurrentPage"])
        }
        if ("ShareHolderArray" in uplodatedFile) {
            setShareHolderArray(uplodatedFile["ShareHolderArray"])
        }
        if ("CompanyFormationObject" in uplodatedFile) {
            setCompanyFormationObject(uplodatedFile["CompanyFormationObject"])
        }
        if ("CompanyFormationShareArray" in uplodatedFile) {
            setCompanyFormationShareArray(uplodatedFile["CompanyFormationShareArray"])
        }
        if ("ShareHolderRelation" in uplodatedFile) {
            setShareHolderRelation(uplodatedFile["ShareHolderRelation"])
        }
    }
    */}

    const handleBack = () => {
        setLastCurrentPage(currentPage);
        setCurrentPage(currentPage - 1);
    }
    const handleNext = () => {
        setLastCurrentPage(currentPage);
        setCurrentPage(currentPage + 1);
    }


    const loadTranslation = (lang:string) => {
        if (lanuageSection !== undefined) {
            i18n.addResourceBundle(lang, 'translation', lanuageSection[lang], true, true);
        }
        
        /*
        setLanguageIsLoading(true);

        try {
          const response = await fetch(`/locales/${lang}/registeredassociation_founding.json`);
          const translations = await response.json();
          i18n.addResourceBundle(lang, 'translation', translations, true, true);
          i18n.changeLanguage(lang);
          setLanguageIsLoading(false);
          setErrorLanguage(false);
        } catch (error) {
            setErrorLanguage(true);
            setLanguageIsLoading(false);
            
          console.error('Fehler beim Laden der Übersetzungen', error);
        }
        */
      };
    
      useEffect(() => {
        if (lanuageSection !== undefined) {
            i18n.addResourceBundle(i18n.language, 'translation', lanuageSection[i18n.language], true, true);
            i18n.changeLanguage(i18n.language);
        }
      }, [i18n.language,lanuageSection]);



    if (!wasSuccessfullyLanguage || !wasSuccessfullCompanyFormationOptionArray || !wasSuccessfullCompanyFormationQuestionArray) {
        return <Alert severity="warning">Unser Gründungsdialog ist vorübergehend nicht freigeschaltet.<br/>Bitte
            versuchen Sie es später erneut.</Alert>
    } else if (lanuageSection === undefined || companyFormationOptionArray === undefined || companyFormationQuestionArray === undefined ) {
        return <CustomCircularProgress/>
    } else {
        return (
            <>
                <DialogFrame
                    configObject={configObject}
                    title={t("dialog_title")}
                    currentPage={currentPage}
                    stepperArray={[
                        <Step key="founding1" completed={currentPage > 0}><StepLabel>{t("menu_item_1")}</StepLabel></Step>,
                        <Step key="founding2" completed={currentPage > 1}><StepLabel>{t("menu_item_2")}</StepLabel></Step>,
                        <Step key="founding3" completed={currentPage > 2}><StepLabel>{t("menu_item_3")}</StepLabel></Step>,
                        <Step key="founding4" completed={currentPage > 3}><StepLabel>{t("menu_item_4")}</StepLabel></Step>,
                        <Step key="founding5" completed={currentPage > 4}><StepLabel>{t("menu_item_5")}</StepLabel></Step>
                    ]}
                    headerButtonArray={[
                        <LanguageDropdown/>,
                        <RestoreProgress key="inherit-import" setterFunction={restoreSetterFunction}></RestoreProgress>,
                        <SaveProgress key="inherit-export" idLegaltransaction={1} dataToSave={getDataToSave()} filename={"Vereinsgründung"}></SaveProgress>
                    ]}
                    txt={txt}
                >
                    <CustomeSlide currentPage={currentPage} pageNumber={0} lastCurrentPage={lastCurrentPage}>
                        <RABaseInformation
                            setTxt={setTxt}
                            currentStep={currentPage}
                            companyFormationObject={companyFormationObject}
                            setCompanyFormationObject={setCompanyFormationObject}

                            handleBack={handleBack}
                            handleNext={handleNext}

                            isImportedFromHandelsregister={isImportedFromHandelsregister}
                            isTranslationEnabled={true}
                        />
                    </CustomeSlide>


                    <CustomeSlide currentPage={currentPage} pageNumber={1} lastCurrentPage={lastCurrentPage}>
                        <RABoardMemebers
                            companyFormationObject={companyFormationObject}
                            setCompanyFormationObject={setCompanyFormationObject}
                            setTxt={setTxt}
                            currentStep={currentPage}
                            handleBack={handleBack}
                            handleNext={handleNext}

                            shareHolderArray={shareHolderArray}
                            setShareHolderArray={setShareHolderArray}
                            companyFormationShareArray={companyFormationShareArray}
                            setCompanyFormationShareArray={setCompanyFormationShareArray}
                            shareHolderRelation={shareHolderRelation}
                            setShareHolderRelation={setShareHolderRelation}
                        />
                    </CustomeSlide>

                    <CustomeSlide currentPage={currentPage} pageNumber={2} lastCurrentPage={lastCurrentPage}>
                        <RARepresentative
                            setTxt={setTxt}
                            currentStep={currentPage}

                            companyFormationObject={companyFormationObject}
                            setCompanyFormationObject={setCompanyFormationObject}
                            shareHolderArray={shareHolderArray}
                            setShareHolderArray={setShareHolderArray}
                            shareHolderRelation={shareHolderRelation}
                            setShareHolderRelation={setShareHolderRelation}
                            companyFormationAnswerArray={companyFormationAnswerArray}
                            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}

                            companyFormationOptionArray={companyFormationOptionArray}
                            companyFormationQuestionArray={companyFormationQuestionArray}

                            handleBack={handleBack}
                            handleNext={handleNext}
                        />
                    </CustomeSlide>

                    <CustomeSlide currentPage={currentPage} pageNumber={3} lastCurrentPage={lastCurrentPage}>
                        <RAConstitution
                            handleBack={handleBack}
                            handleNext={handleNext}
                            documentArray={documentArray}
                            setDocumentArray={setDocumentArray}
                            
                            companyFormationAnswerArray={companyFormationAnswerArray}
                            setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
                            companyFormationOptionArray={companyFormationOptionArray}
                            companyFormationQuestionArray={companyFormationQuestionArray}
                        />
                    </CustomeSlide>


                    <CustomeSlide currentPage={currentPage} pageNumber={4} lastCurrentPage={lastCurrentPage}>
                        <RASummary
                            configObject={configObject}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            companyFormationObject={companyFormationObject}
                            setCompanyFormationObject={setCompanyFormationObject}
                            companyFormationShareArray={companyFormationShareArray}
                            shareHolderArray={shareHolderArray}
                            setShareHolderArray={setShareHolderArray}
                            shareHolderRelation={shareHolderRelation}
                            companyFormationAnswerArray={companyFormationAnswerArray}
                            companyFormationOptionArray={companyFormationOptionArray}
                            companyFormationQuestionArray={companyFormationQuestionArray}
                            documentArray={documentArray}
                            setDocumentArray={setDocumentArray}
                        />
                    </CustomeSlide>

                    <CustomeSlide currentPage={currentPage} pageNumber={5} lastCurrentPage={lastCurrentPage}>
                        <Send
                            isTranslationEnabled={true}
                        />
                    </CustomeSlide>
                </DialogFrame>

            </>
        )
    }
}
