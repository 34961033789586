import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    handleBack: Function;
    handleNext: Function;
}


export const StartFounding:React.FC<IProps> = (props) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Firmengründung</Typography>

            <Typography>
                Herzlich willkommen auf unserer Seite zur Gründung Ihrer eigenen GmbH! Hier erfahren Sie alles,
                was Sie benötigen, um den Gründungsprozess erfolgreich abzuschließen. Wir begleiten Sie Schritt
                für Schritt und stehen Ihnen bei Fragen jederzeit zur Verfügung.
                <p />
                Zu Beginn sollten Sie sich überlegen, welchen Firmennamen Sie für Ihre GmbH wählen möchten.
                Der Name sollte einprägsam sein und Ihr Geschäftsfeld widerspiegeln. Wir unterstützen Sie gerne
                bei der Namensfindung und prüfen auch, ob der gewünschte Name bereits vergeben ist.
                <p />
                Im nächsten Schritt benötigen Sie eine Satzung für Ihre GmbH. Hierin werden unter anderem der Zweck
                der Gesellschaft, die Höhe des Stammkapitals und die Geschäftsführung festgelegt. Wir stellen Ihnen
                eine Vorlage für die Satzung zur Verfügung und unterstützen Sie bei der Anpassung an Ihre individuellen
                Bedürfnisse.
                <p />
                Nach der Gründungsversammlung erfolgt die Eintragung Ihrer GmbH ins Handelsregister. Hierfür benötigen Sie eine notariell beglaubigte Kopie der Satzung sowie den Nachweis über die Einzahlung des Stammkapitals. Wir unterstützen Sie bei der Zusammenstellung der benötigten Unterlagen und übernehmen die Anmeldung beim Handelsregister für Sie.
                <p />
                Sobald Ihre GmbH ins Handelsregister eingetragen ist, erhalten Sie die Gründungsbescheinigung. Herzlichen Glückwunsch, Ihre GmbH ist nun offiziell gegründet! Wir stehen Ihnen auch nach der Gründung gerne zur Seite und unterstützen Sie bei allen Fragen rund um Ihre GmbH.
                <p />
                Zögern Sie nicht länger und gründen Sie noch heute Ihre eigene GmbH! Wir freuen uns darauf, Sie bei diesem spannenden Vorhaben begleiten zu dürfen.
                <p />
                <i>*Sie können ihren Fortschitt über "Sichern" exportieren und jederzeit nahtlos weitermachen</i>
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}